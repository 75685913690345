body {
  font-family: "gill-sans-nova", Arial, sans-serif;
}

html {
  scroll-behavior: smooth;
}

.none {
  display: none !important;
}

a {
  text-decoration: none;
  color: inherit;

  &.link {
    text-decoration: underline;
  }
}

.clearfix {
  clear: both;
}

div.call-to-action {
  display: flex;
  justify-content: center;

  a {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding-bottom: 4px;
    color: #ffffff;
    background-color: #86c0b4;

    font-size: 18px;
    font-weight: 400;
    line-height: 20px;

    width: 375px;
    height: 60px;
    border-radius: 30px;
    border: none;

    cursor: pointer;

    > h3 {
      font-size: 22px;
      color: #ffffff;
      text-align: center;
    }

    .icon {
      height: 28px;
    }

    &:hover {
      background-color: rgb(82, 112, 107);
    }
  }
}

.App {
  position: relative;

  .partner-container {
    position: absolute;
    background-color: #e34d3c;
    width: 100%;
    height: 200px;
    bottom: -80px;
    z-index: 4;

    .designed-by {
      padding: 10px;
      text-align: center;
    }

    .partners {
      max-width: 940px;
      margin: 10px auto;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 25px;

      .partner-logos-container {
        display: flex;
        justify-content: center;
        gap: 40px;

        &.mobile {
          display: none;
        }
        &.desktop {
          display: flex;
        }

        &.small {
          justify-content: center;
          gap: 40px;
        }
        .partner-logo-container {
          .partner-logo {
            height: 50px;
          }
        }
      }

      .copyright-container {
        display: flex;
        justify-content: space-between;

        .link-collection {
          display: flex;
          justify-content: space-between;
          gap: 12px;
        }
      }
    }
  }
}

.header {
  height: 98px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  z-index: 3;
  position: relative;
}

.header-container {
  max-width: 940px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .slogan {
    position: relative;
    top: 16px;
    color: #52706b;
    font-size: 16px;
    padding: 4px 14px;
    border: 1px dashed #526a6b;
    transform: rotate(4deg);
    border-radius: 12px;
    font-weight: lighter;
    text-align: center;
    height: fit-content;

    .bold {
      font-weight: bolder;
    }

    .head {
      font-weight: bold;
      font-size: 18px;
      display: block;
      margin: 0;
    }

    .text-0 {
      line-height: 14px;
      margin-bottom: 4px;
    }

    .text-1 {
      line-height: 20px;
    }
  }

  .logo-container {
    position: relative;
    top: 26px;
    max-width: 140px;
    max-height: 98px;
  }

  .menu-container {
    height: 98px;
    gap: 30px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .menu-item {
      color: #4d4044;
      border-bottom: 3px solid rgba(255, 255, 255, 0);

      font-family: KoHo;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;

      height: 100%;
      display: flex;
      align-items: center;

      &.active,
      &:hover {
        color: #e34d3c;
        border-bottom: 3px solid #e34d3c;
      }
    }
  }
}

.Vision-main {
  .slideshow-area {
    display: flex;
    justify-content: center;

    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
}

.Team-main,
.Sponsors-main {
  .main-image {
    max-width: 100%;
  }
}

.main {
  position: relative;
  z-index: 1;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 160px;
  margin-top: 80px;
  min-height: 500px;

  &.is-tour {
    margin-top: 0;
    margin-bottom: 60px;

    .main-container {
    }
  }

  &.is-home {
    margin-bottom: 0px;
    margin-top: 0px;
    min-height: 1355px;
    background-image: url("../assets/map-background-2.png");
    background-size: 1720px;
    background-position: calc(50%);
    background-position-y: -380px;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .route-container {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    &.is-home {
      background-image: url("../assets/Route.svg");
      background-size: 653px;
      background-position: calc(50%);
      background-position-y: 45px;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }

    &.is-blog {
      background: none;
    }
  }

  .main-container {
    max-width: 940px;
    margin: 0 auto;
    height: 100%;

    .tour-route-container {
      height: 700px;
      position: relative;

      .info-container {
        opacity: 0;
        display: flex;
        position: absolute;
        width: 400px;
        top: 42px;
        right: 15px;
        z-index: 10;
        bottom: 75px;
        overflow: hidden;
        flex-direction: column;
        background: rgba(82, 112, 107, 0.85);
        border-radius: 10px;

        justify-content: start;
        align-items: center;

        &.visible {
          opacity: 1;
        }

        .poi-container {
          width: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 0;
          z-index: 5;

          .info-etappe-line {
            position: absolute;
            top: -15px;
            height: 110%;
            z-index: 0;
            opacity: 0.5;

            div,
            svg {
              width: 100%;
              height: 100%;
            }
          }

          .poi-item {
            display: flex;
            flex-direction: column;
            width: 100%;

            > div {
              z-index: 10;
              position: relative;
            }

            .info-etappe-image-slider {
              padding: 0px;
              height: 200px;
              width: 100%;

              .rtds-single-slide-styles {
                div {
                  padding: 0 !important;
                }

                img {
                  max-height: initial;
                }
              }

              .caption-text {
                text-align: center;
                font-size: 15px;
                line-height: 15px;
                color: #ffffff;
                position: absolute;
                bottom: 10px;
                left: 5px;
                right: 5px;
                background-color: rgba(77, 63, 68, 0.8);
                border-radius: 10px;
                margin-left: auto;
                margin-right: auto;
                padding: 5px 10px;
                padding-bottom: 8px;
                width: fit-content;
              }
            }
            .info-etappe-image-slider-indicator {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin: 5px 0;
              margin-top: 0;
              gap: 10px;

              .indicator-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;
                align-items: center;

                .dots {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  margin: 5px 0;
                  gap: 6px;

                  .dot {
                    cursor: pointer;
                    width: 12px;
                    height: 12px;
                    background-color: #626262;
                    border-radius: 100%;

                    &.active {
                      background-color: #4c4145;
                    }
                  }
                }
              }
            }

            .info-etappe-poi-title {
              display: flex;
              align-items: center;
              gap: 15px;
              width: 100%;
              margin: 10px 0;
              font-family: KoHo;
              font-size: 30px;
              font-weight: 500;
              color: rgba(134, 192, 180, 1);
              line-height: 36px;

              .icon {
                width: 28px;
                height: 28px;
                margin-left: 14px;
                border-radius: 100%;
                background-color: #87c0b4;
                justify-content: center;
                align-items: center;
                display: flex;
              }
              svg {
                display: block;
              }

              .text {
                flex: 1;
                padding-right: 10px;
              }
            }

            .info-etappe-poi-text {
              padding-left: 58px;
              padding-right: 10px;
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
              line-height: 23px;
              margin-bottom: 20px;
            }
          }
        }

        > div {
          padding: 0px 10px;
          z-index: 10;
        }

        .info-etappe-name {
          .text {
            padding-top: 10px;
            color: rgba(134, 192, 180, 1);
            font-size: 22px;
            font-weight: 400;
            line-height: 31.55px;
            text-align: center;
          }

          .close-icon {
            position: absolute;
            transform: scale(0.8);
            right: 4px;
            top: 4px;
            cursor: pointer;

            svg {
              path,
              circle {
                stroke: rgba(235, 138, 116, 1);
              }
            }
          }
        }

        .info-etappe-title {
          color: #ffffff;
          font-family: KoHo;
          font-size: 36px;
          font-weight: 500;
          line-height: 46.8px;
          text-align: center;
        }

        .info-etappe-details {
          padding-top: 10px;
          padding-bottom: 20px;
          gap: 40px;
          color: rgba(134, 192, 180, 1);
          font-size: 22px;
          font-weight: 400;
          line-height: 16px;
          display: flex;
          flex-direction: row;

          .content-left-info-left,
          .content-left-info-right {
            display: flex;
            gap: 10px;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            > div > div {
              svg {
                transform: scale(0.8);
              }
            }
          }
        }
      }

      .options-container {
        justify-content: center;
        align-items: center;
        margin: 10px;
        margin-right: 0;
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 3;
        display: flex;
        flex-direction: row;
        gap: 10px;
        cursor: pointer;

        .options-checkbox {
          width: 24px;
          height: 24px;
          border-radius: 35%;
          background-color: #f1f3f2;

          .icon {
            display: none;

            div,
            svg {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          &.active {
            .icon {
              display: inherit;
            }
          }
        }

        .options-label {
          color: #4c4145;
          white-space: nowrap;
          margin-bottom: 1px;
        }
      }

      .route-line,
      .map-big {
        width: 100%;
        position: absolute;
        height: 100%;

        .etappe-1 {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;

          .start,
          .end {
            cursor: pointer;
            position: absolute;
            width: 34px;
            height: 34px;
            background-color: #87c0b4;
            border-radius: 100px;
            border: solid 4px #e34d3c;

            .icon {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;

              svg {
                display: block;
              }
            }

            .name {
              position: absolute;
              font-size: 32px;
              line-height: 32px;
              font-family: "koho";
              color: #000000;
            }

            .poi {
              position: absolute;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 6px;

              .poi-icon {
                width: 18px;
                height: 18px;
                background-color: #e34d3c;
                border-radius: 100%;
                flex: 1 1;
              }

              .poi-name {
                flex: 1 1;
                white-space: nowrap;
                font-size: 18px;
                line-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 3px;
                color: #6c565d;
                font-weight: 500;
              }
            }
          }

          .stop {
            cursor: pointer;
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: rgba(0, 0, 0, 0);

            .icon {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;

              svg {
                display: block;
                width: 100%;
                height: 100%;
              }
            }

            .name {
              position: absolute;
              white-space: nowrap;
              line-height: 18px;
              padding-bottom: 3px;
              color: #000000;
              font-weight: 500;
              left: 26px;
              top: 0px;

              &.is-right {
                left: initial;
                right: 26px;
                top: -1px;
              }
            }
          }

          .pois {
            cursor: pointer;
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: rgba(0, 0, 0, 0);

            &.hide {
              display: none;
            }

            .icon {
              display: flex;
              width: 100%;
              height: 100%;
              justify-content: center;
              align-items: center;

              border-radius: 100%;
              border: solid 2px #e34d3c;
            }

            .name {
              position: absolute;
              white-space: nowrap;
              line-height: 18px;
              padding-bottom: 3px;
              color: #6c565d;
              font-weight: 500;
              left: 18px;
              top: -3px;

              &.is-right {
                left: initial;
                right: 14px;
                top: -5px;
              }
            }
          }
        }
      }

      .route-line {
        z-index: 1;
      }

      .map-big {
        z-index: 0;
      }
    }

    .home-container {
      padding-top: 100px;

      .main-sponsoring-container {
        .main-sponsoring {
          margin-top: 20px;
          padding-bottom: 140px;
          padding-top: 10px;
          padding-left: 20px;
          background-color: white;
          border-radius: 10px;
          padding-right: 20px;

          h2 {
            text-align: center;
            text-transform: uppercase;
            font-family: KoHo;
            font-size: 32px;
            font-weight: 700;
            line-height: 41.6px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #52706b;

            &.second {
              text-transform: none;
            }
          }

          p {
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            margin-bottom: 40px;

            a {
              color: #e34d3c;
            }
          }

          .main-sponsoring-element-container {
            display: none;

            .main-sponsoring-element-title {
              h2 {
                text-align: center;
                text-transform: none;
                font-family: KoHo;
                font-size: 32px;
                font-weight: 700;
                line-height: 41.6px;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #52706b;
              }
            }

            .main-sponsoring-element-image,
            .main-sponsoring-element-image-container
              .main-sponsoring-element-image {
              border: solid 1px grey;
              border-radius: 16px;
              padding: 16px;
              position: relative;

              img {
                width: 100%;
                border-radius: 10px;
                border: solid 1px grey;
              }

              &.is-active {
                img {
                  border-radius: 0px;
                  border: none;
                }
              }

              .ribbon {
                font-size: 17px;
                font-weight: bold;
                color: #fff;

                &.is-mobile {
                  display: none;
                }
              }

              .ribbon {
                --f: 0.5em; /* control the folded part */

                position: absolute;
                top: 0;
                right: 0;
                line-height: 1.2;
                text-align: center;
                padding-inline: 2.2lh;
                padding-bottom: 12px;
                border-image: conic-gradient(#0008 0 0) 51% / var(--f);
                clip-path: polygon(
                  100% calc(100% - var(--f)),
                  100% 100%,
                  calc(100% - var(--f)) calc(100% - var(--f)),
                  var(--f) calc(100% - var(--f)),
                  0 100%,
                  0 calc(100% - var(--f)),
                  999px calc(100% - var(--f) - 999px),
                  calc(100% - 999px) calc(100% - var(--f) - 999px)
                );
                transform: translate(calc((1 - cos(45deg)) * 100%), -100%)
                  rotate(45deg);
                transform-origin: 0% 100%;
                background-color: #57657f; /* the main color  */
              }
            }
          }

          .main-sponsoring-element-container.is-gold,
          .main-sponsoring-element-container.is-silver {
            // display: flex;
            display: none;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;

            .main-sponsoring-element-image-container {
              max-width: 75%;
              display: flex;
              flex-direction: column;
              gap: 20px;

              .main-sponsoring-element-image {
                .ribbon {
                  font-size: 13px;
                  background-color: #9a8022;
                }
              }
            }
          }

          .main-sponsoring-element-container.is-silver {
            .main-sponsoring-element-image-container {
              max-width: 60%;
              display: flex;
              flex-direction: column;
              gap: 20px;

              .main-sponsoring-element-image {
                .ribbon {
                  font-size: 10px;
                  background-color: #626262;
                }
              }
            }
          }
        }
      }

      .content-grid {
        display: flex;
        gap: 20px;

        .content-left {
          background-color: rgba(82, 112, 107, 0.9);
          border-radius: 10px;
          padding: 27px;
          color: #ffffff;
          display: flex;
          flex: 0.6 1;
          flex-direction: column;
          gap: 30px;
          align-items: center;

          .content-left-title {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            h1 {
              margin: 0 12px;
              font-family: KoHo;
              font-size: 36px;
              font-weight: 700;
              line-height: 36px;
            }
          }

          .content-left-subtitle {
            text-align: center;
          }
          .content-left-maintitle {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            gap: 10px;

            h1 {
              margin: 0 12px;
              font-family: KoHo;
              font-size: 36px;
              font-weight: 700;
              line-height: 36px;
              margin: 0px;
            }
          }

          .content-left-info {
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 34px;
            margin: 20px 0;

            font-size: 24px;
            font-weight: 400;
            line-height: 30px;

            .content-left-info-left {
              display: flex;
              gap: 10px;
              justify-content: flex-start;
              align-items: center;

              > div {
                height: 28px;
              }
            }

            .content-left-info-right {
              display: flex;
              gap: 10px;
              justify-content: flex-end;
              align-items: center;

              > div {
                height: 28px;
              }
            }
          }

          .content-left-text {
            padding: 0 15px;

            p {
              font-size: 20px;
              font-weight: 400;
              line-height: 22px;
              margin-top: 0;
            }
          }

          .content-left-button {
            a {
              display: flex;
              justify-content: center;
              gap: 10px;
              align-items: center;
              color: #000000;
              background-color: #ffffff;

              font-size: 20px;
              font-weight: 400;
              line-height: 20px;

              width: 340px;
              height: 55px;
              border-radius: 30px;
              border: none;

              cursor: pointer;

              .icon {
                height: 28px;
              }

              &:hover {
                background-color: #dddddd;
              }
            }
          }
        }

        .content-right {
          display: flex;
          flex-direction: column;
          gap: 20px;
          flex: 1;

          .content-right-top,
          .content-right-middle,
          .content-right-bottom {
            .content-right-image {
              .content-right-image-circle {
                float: left;
                width: 150px;
                height: 150px;
                margin-right: 20px;
                margin-bottom: 20px;
                border: 6px solid #eb8a74;
                border-radius: 100%;
                background-size: 170px;
                background-repeat: no-repeat;

                &.meeting-line {
                  background-image: url("../assets/Meeting-bg.jpg");
                  background-position: calc(50% - 10px) calc(50% + 0px);
                }

                &.mission {
                  background-image: url("../assets/Mission-bg.png");
                  background-position: calc(50% - 0px) calc(50% + 3px);
                  background-size: 160px;
                }

                &.team {
                  background-image: url("../assets/Team-bg.jpg");
                  background-position: calc(50% - 2px) -16px;
                }
              }
            }
          }

          .content-right-top {
            background-color: rgba(82, 112, 107, 0.9);
            border-radius: 10px;
            padding: 27px;
            color: #ffffff;
            display: flex;
            gap: 0px;
          }

          .content-right-middle {
            background-color: rgba(82, 112, 107, 0.9);
            border-radius: 10px;
            padding: 27px;
            color: #ffffff;
            display: flex;
            gap: 0px;
          }

          .content-right-bottom {
            background-color: rgba(82, 112, 107, 0.9);
            border-radius: 10px;
            padding: 27px;
            color: #ffffff;
            display: flex;
            gap: 0px;
          }

          .content-right-bottom,
          .content-right-middle,
          .content-right-top {
            flex: 1;
            flex-direction: column;

            .content-right-content-title {
              h2 {
                margin: 0px;
                margin-bottom: 20px;
                font-family: KoHo;
                font-size: 17px;
                font-weight: 700;
                line-height: 20px;
                text-transform: uppercase;
              }
            }

            .content-right-content-text {
              font-size: 20px;
              font-weight: 400;
              line-height: 22px;

              p {
                margin-top: 0;
                margin-bottom: 20px;
              }
            }

            .content-right-content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .content-right-content-button {
                a {
                  display: flex;
                  justify-content: center;
                  gap: 10px;
                  align-items: center;
                  color: #ffffff;
                  background-color: #86c0b4;

                  font-size: 20px;
                  font-weight: 400;
                  line-height: 20px;

                  width: 200px;
                  height: 45px;
                  border-radius: 30px;
                  border: none;

                  cursor: pointer;

                  .icon {
                    height: 28px;
                  }

                  &:hover {
                    background-color: rgb(82, 112, 107);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.is-blog {
    margin-bottom: 0px;
    margin-top: 0px;
    min-height: 1500px;
    background: none;

    .main-container {
      max-width: 100%;
      margin: 0;
      height: 100%;

      .main-image {
        img {
          width: 100%;
          display: block;
        }
      }

      .main-head {
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: 100%;
        padding: 20px 0;
        padding-top: 20px;
        background-color: #86c0b4;

        .main-head-content {
          padding-bottom: 100px;
          position: relative;
          max-width: 950px;
          margin: 0 auto;

          .main-head-line {
            background-image: url("../assets/LineTopText.svg");
            background-size: contain;
            background-repeat: no-repeat;

            position: absolute;
            width: 349px;
            height: 499px;
            bottom: -30px;
            left: -200px;
            z-index: 0;
          }

          .main-head-text-container {
            margin: 0 20px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            position: relative;
            z-index: 1;
            align-items: center;

            .main-head-text {
              text-align: justify;
              max-width: 950px;
              column-count: 2;
              column-gap: 20px;
              column-fill: balance;

              p {
                margin: 0;
                margin-bottom: 16px;
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;

                a {
                  color: #e34d3c;
                }
              }
            }

            .main-head-title {
              max-width: 950px;
              width: 100%;

              h2 {
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                font-family: KoHo;
                font-size: 32px;
                font-weight: 700;
                line-height: 38px;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              }
            }
          }
        }
      }

      .main-container {
        max-width: 950px;
        margin: 0 auto;
        position: relative;
        z-index: 0;
        overflow: hidden;
        height: fit-content;
        margin-bottom: 80px;
        padding-top: 238px;
        padding-bottom: 100px;

        .main-content-title {
          position: relative;
          z-index: 2;
          height: 100px;
          h1 {
            text-align: left;
            text-transform: uppercase;
            font-family: KoHo;
            font-size: 32px;
            font-weight: 700;
            line-height: 41.6px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #52706b;
            margin-left: 470px;
          }
        }

        .main-content-text {
          position: relative;
          z-index: 1;
          display: flex;
          flex-direction: column;
          gap: 40px;
          max-width: 710px;
          margin: 0;
          margin-left: 180px;

          .team-member-item-container,
          .sponsor-badge-item-container {
            display: flex;
            gap: 40px;
            flex-direction: row;
            align-items: flex-start;

            .team-member-item-image,
            .sponsor-badge-item-image {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 250px;

              img {
                width: 250px;
                border-radius: 100%;
                border: 6px solid #eb8a74;
                height: 250px;
              }
            }

            .sponsor-badge-item-image {
              img {
                border: none;
              }
            }

            .team-member-item-text-container,
            .sponsor-badge-item-text-container {
              flex: 1 1;
              padding-top: 0px;

              .team-member-item-text-title,
              .sponsor-badge-item-text-title {
                h3 {
                  font-family: KoHo;
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 26px;
                  text-align: left;
                  text-decoration-skip-ink: none;
                }
              }

              .sponsor-badge-item-text-text {
                &.is-bold {
                  font-weight: bold;
                }
              }

              .sponsor-badge-item-text-list {
                a {
                  color: #e34d3c;
                }
              }

              .sponsor-badge-item-partner-list {
                display: flex;
                max-width: 450px;
                justify-content: center;
                margin: 10px 0;
                gap: 10px;
                flex-wrap: wrap;

                .sponsor-badge-item-partner-list-item {
                  border-radius: 10px;
                  border: solid 1px grey;
                  overflow: hidden;
                  box-sizing: border-box;
                  flex: 1 1 100%;
                  max-width: 100%;

                  &.is-active {
                    border: none;
                  }
                  &.is-gold {
                    flex: 1 1 48%;
                    max-width: 48%;
                  }

                  &.is-silver {
                    flex: 1 1 31%;
                    max-width: 31%;
                  }

                  img {
                    width: 100%;
                    display: block;
                  }
                }
              }
            }
          }

          .team-member-item-container:nth-child(even),
          .sponsor-badge-item-container:nth-child(even) {
            flex-direction: row-reverse;
          }
        }

        .main-content-line {
          background-image: url("../assets/LineContentText.svg");

          background-repeat: no-repeat;
          position: absolute;
          z-index: 0;
          top: -635px;
          left: 83px;
          height: 1680px;
          width: 909px;
        }

        .main-content-line-2 {
          background-image: url("../assets/LineContentText.svg");
          background-position-y: -1055px;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 0;
          top: 1045px;
          left: 83px;
          height: 2256px;
          width: 909px;
        }

        > div > p:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.main.is-blog {
  .main-container {
    .Sponsors-main {
      .main-container {
        padding-top: 100px;

        .main-content-title {
          h1 {
            margin-left: 440px;
          }
        }
        .main-content-text {
          max-width: 775px;
          margin-left: 150px;
          gap: 65px;
        }
      }
    }
  }
}

.footer {
  z-index: 2;
  position: relative;
  top: -120px;
}

.footer-container {
  height: 830px;
  position: relative;
  overflow: hidden;

  .upper-circle {
    position: absolute;
    background-color: #eb8a74;
    width: 8500px;
    height: 11000px;
    left: calc(-4250px + 50%);
    border-radius: 100%;
    border: 28px solid #e34d3c;

    .contact-content {
      position: relative;
      display: flex;
      max-width: 830px;
      margin: 0 auto;
      top: 20px;
      color: white;
      text-align: center;
      padding-top: 20px;
      justify-content: space-between;
      align-items: center;

      .logo-container {
        max-width: 310px;
        position: relative;

        &.big-logo {
          top: -50px;
          svg {
            width: 100%;
          }
        }
      }

      .line-container {
        position: absolute;
        top: 0px;
        left: 420px;
      }

      .form-content {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        align-items: flex-end;
        margin-bottom: 100px;

        .form-title {
          font-family: KoHo;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;

          h3 {
            margin: 0;
          }
        }

        .form-container {
          background-color: #ffffff;
          width: 500px;
          min-height: 300px;
          filter: drop-shadow(-10px 10px 0px #e34d3c);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .lower-circle {
    position: absolute;
    background-color: #e34d3c;
    width: 8500px;
    height: 11000px;
    left: calc(-4250px + 50%);
    top: 680px;
    border-radius: 100%;

    .partners-content {
      position: relative;
      max-width: 660px;
      margin: 0 auto;
      top: 20px;
      color: white;
      text-align: center;

      h2 {
        font-family: KoHo;
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .header {
    position: fixed;
    width: 100vw;
    height: 200px;

    .header-container {
      max-width: 100%;
      height: 100%;
      padding: 0;
      flex-direction: column;

      .logo-container {
        padding-left: 10px;
        max-width: 120px;
        max-height: 120px;

        svg {
          width: 120px;
          height: 120px;
        }
      }

      .slogan {
        position: absolute;
        top: 45px;
        right: 10px;
        padding: 4px 10px;
        font-size: 15px;

        .head {
          margin: 0;
          font-size: 18px;
        }
      }

      .menu-container {
        height: 50px;
        margin: 0 20px;
        gap: 20px;

        .menu-item {
          font-size: 18px;
        }
      }
    }
  }

  .footer-container {
    max-width: 100%;
  }

  .main {
    margin-top: 0px;
    padding: 0 20px;
    padding-top: 200px;
    background-attachment: fixed;

    &.is-home {
      background-position-y: -82px;
    }

    .route-container {
      &.is-home {
        background-attachment: fixed;
        background-position-y: 194px;
        background-size: 444px;
      }

      &.is-blog {
        background: none;
      }
    }

    &.is-home {
      background-image: url("../assets/map-background.png");
      padding: 0;
      padding-top: 200px;
      background-size: 1166px;
    }

    &.is-blog {
      padding: 0;
      padding-top: 200px;
      background: none;

      .main-container {
        .main-container {
          padding-bottom: 25px;

          .main-content-title {
            margin: 0 20px;
          }
          .main-content-text {
            .sponsor-badge-item-container {
              margin: 0 20px;
              flex-direction: column !important;
              align-items: center;
              gap: 20px;
              text-align: center;

              .sponsor-badge-item-text-title {
                display: flex;
                justify-content: center;
                h3 {
                  text-align: center !important;
                  max-width: 300px;
                }
              }
            }
          }
        }
      }
    }

    .main-container {
      .home-container {
        padding: 20px 10px;

        .content-grid {
          flex-direction: column;

          .content-left {
            .content-left-text {
              padding: 0px;
            }
          }
        }

        .main-sponsoring-container {
          border-radius: 10px;
          background-color: #ffffff;
          padding: 20px;
          margin-top: 20px;

          .main-sponsoring {
            margin-top: 10px;
            padding-bottom: 40px;
          }

          .ribbon {
            display: none;
          }

          .ribbon.is-mobile {
            display: inherit !important;
            font-size: 10px !important;
            line-height: 1.1 !important;
            padding-inline: 1.5lh !important;
            padding-bottom: 8px !important;

            &.is-gold {
              font-size: 8.5px !important;
            }

            &.is-silver {
              font-size: 7px !important;
            }
          }
        }
      }

      .container {
        .Team-main,
        .Sponsors-main {
          .main-head {
            .main-head-text-container {
              .main-head-text {
                column-count: 1;
              }
            }
          }
          .main-container {
            padding-top: 120px;
            height: fit-content;
            margin-bottom: 60px;

            .main-content-title {
              h1 {
                margin-left: 0px;
                text-align: center;
              }
            }
            .main-content-text {
              margin-left: 0px;
              max-width: 100%;
              gap: 80px;

              .team-member-item-container,
              .sponsor-bagde-item-container {
                flex-direction: column;
                align-items: center;
                gap: 0px;

                .team-member-item-text-container,
                .sponsor-bagde-item-text-container {
                  padding: 0 20px;
                  text-align: center;
                  max-width: 500px;

                  .team-member-item-text-title,
                  .sponsor-bagde-item-text-title {
                    h3 {
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .content-right-image {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .main-container {
      max-width: 100%;
      margin: 0 20px;
    }
  }

  .footer {
    top: -10px;

    .footer-container {
      height: 1065px;

      .lower-circle {
        top: 880px;

        .partners-content {
          max-width: calc(100vw - 50px);
        }
      }
      .upper-circle {
        .contact-content {
          max-width: calc(100vw - 50px);
          left: -25px;
          flex-direction: column;

          .form-content {
            width: 100%;
            .form-container {
              width: 100%;
              min-height: 375px;
            }
          }
          .line-container {
            left: 150px;
            transform: scale(1.6);
            top: 170px;
          }
          .logo-container {
            z-index: 1;
            max-height: 300px;
            margin-bottom: 35px;

            &.big-logo {
              top: 0px;
            }

            svg {
              height: 300px;
            }
          }
        }
      }
    }
  }

  .App {
    .partner-container {
      bottom: -135px;
      height: 150px;

      .partners {
        gap: 20px;

        .copyright-container {
          padding: 0 20px;
        }

        .partner-logos-container {
          gap: 20px;
          justify-content: center;

          &.small {
            gap: 20px;

            .partner-logo-container {
              height: 35px;

              .partner-logo {
                height: 100%;
              }
            }
          }

          &.mobile {
            display: flex;
          }
          &.desktop {
            display: none;
          }

          .partner-logo-container {
            height: 35px;

            .partner-logo {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
